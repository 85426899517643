import 'lodash/noop';
import { Map, get } from 'immutable';
import 'prop-types';
import 'react-immutable-proptypes';
import 'universal-cookie';
import { COOKIE_OPTIONS_DEFAULT, setImmutableCookie, removeCookie, getImmutableCookie } from '@dbh/cookies';
import { COOKIE_KEYS } from '@dbh/cookie-keys';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const NEVER_EXPIRE_COOKIE_OPTIONS=Object.freeze({...COOKIE_OPTIONS_DEFAULT,maxAge:"2137483647"});const EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS=Object.freeze({...COOKIE_OPTIONS_DEFAULT,maxAge:"2592000"});

/**
 * Returns the "anonymous ID" cookie, that attempts to uniquely identify an user
 * that is not signed in, since it "never expires" until the browser's storage
 * is cleared.
 * @param {Object} cookies An instance of `cookies`.
 * @return {string?} The value.
 */const getImmutableCookieAnonymousId=a=>{const b=getImmutableCookie(a,COOKIE_KEYS.ANONYMOUS_ID),c=Map.isMap(b)?b.get("value"):b;// The old cookie format was a string, so we have to check here.
return c||void 0};/**
 * Sets the "anonymous ID" cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {string} value A `GUID`.
 */const setImmutableCookieAnonymousId=withConformsTo("setImmutableCookieAnonymousId",[],(a,b)=>{setImmutableCookie(a,COOKIE_KEYS.ANONYMOUS_ID,b,NEVER_EXPIRE_COOKIE_OPTIONS);});/**
 * Removes the "anonymous ID" cookie.
 * @param {Object} cookies An instance of `cookies`.
 */const removeImmutableCookieAnonymousId=withConformsTo("removeImmutableCookieAnonymousId",[],a=>{removeCookie(a,COOKIE_KEYS.ANONYMOUS_ID);});/**
 * Returns the "GUID" session cookie: it is a "session cookie" with no expiration
 * date set (default options). It is expected to be cleared by the browser when
 * it is closed, or when the browser's storage is cleared.
 * @param {Object} cookies An instance of `cookies`.
 * @return {string?} The value.
 */const getImmutableCookieGuidSession=a=>get(getImmutableCookie(a,COOKIE_KEYS.GUID_SESSION),"value");/**
 * Sets the "GUID" session cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {string} value A `GUID`.
 */const setImmutableCookieGuidSession=withConformsTo("setImmutableCookieGuidSession",[],(a,b)=>{setImmutableCookie(a,COOKIE_KEYS.GUID_SESSION,b);});/**
 * Sets the "UTM" cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {Immutable.Map} value The `utm` cookie payload.
 */const setImmutableCookieUtm=withConformsTo("setImmutableCookieUtm",[],(a,b)=>{setImmutableCookie(a,COOKIE_KEYS.UTM,b,EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS);});/**
 * Returns the `utm` cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @return {Immutable.Map?} The `utm` payload, if valid.
 */const getImmutableCookieUtm=a=>{const b=get(getImmutableCookie(a,COOKIE_KEYS.UTM),"value");return Map.isMap(b)?b:void 0};/**
 * Removes the `utm` cookie.
 * @param {Object} cookies An instance of `cookies`.
 */const removeImmutableCookieUtm=withConformsTo("removeImmutableCookieUtm",[],a=>{removeCookie(a,COOKIE_KEYS.UTM);});// Keep the `export *` at the bottom and the other types of exports above.

export { EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS, NEVER_EXPIRE_COOKIE_OPTIONS, getImmutableCookieAnonymousId, getImmutableCookieGuidSession, getImmutableCookieUtm, removeImmutableCookieAnonymousId, removeImmutableCookieUtm, setImmutableCookieAnonymousId, setImmutableCookieGuidSession, setImmutableCookieUtm };
